import React from "react";
import { useState } from "react";
import banny from "./images/banny.png";

function App() {
  const [bannyVisible, setBannyVisible] = useState<boolean>(false);
  const audio = new Audio("/win2000.mp3");

  const handleBanny = () => {
    setBannyVisible((prev) => {
      return !prev;
    });
    audio.play();
  };

  const handleHideBanny = () => {
    setBannyVisible((prev) => {
      return !prev;
    });
  };

  return (
    <div className="min-h-screen flex items-center justify-center">
      <div className="grid grid-cols-1 gap-4 p-5">
        {!bannyVisible && (
          <button
            onClick={() => handleBanny()}
            className="bg-green-500 hover:bg-green-400 text-white font-bold py-2 px-4 border-b-4 border-green-700 hover:border-green-500 rounded"
          >
            Press to see Banny!
          </button>
        )}

        {bannyVisible && (
          <img alt="banny" src={banny} onClick={() => handleHideBanny()} />
        )}
      </div>
    </div>
  );
}

export default App;
